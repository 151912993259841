<template>
  <div id="account-setting-page">
    <div class="inner-acc">
      <div class="wrapper rowflex">
        <sidebar-account></sidebar-account>
        <article class="main">
          <div class="inner-trx">
            <div class="filter clearfix">
              <div class="droplist">
                <div class="field-select">
                  <select>
                    <option value="">All transaction</option>
                    <option value="rent">Rent</option>
                    <option value="rent-to-own">Rent-to-Own</option>
                    <option value="buy">Buy</option>
                  </select>
                </div>
                <div class="field-select">
                  <select>
                    <option value="">All status</option>
                    <option value="Waiting Approval">Waiting Approval</option>
                    <option value="Ongoing Rent">Ongoing Rent</option>
                    <option value="Waiting Documents">Waiting Documents</option>
                    <option value="Waiting Start Date"
                      >Waiting Start Date</option
                    >
                    <option value="Past Rent">Past Rent</option>
                    <option value="Canceled">Canceled</option>
                    <option value="Purchase Completed"
                      >Purchase Completed</option
                    >
                    <option value="Purchase Not Completed"
                      >Purchase Not Completed</option
                    >
                    <option value="Waiting Legal Process"
                      >Waiting Legal Process</option
                    >
                    <option value="Waiting Purchase Date"
                      >Waiting Purchase Date</option
                    >
                    <option value="Offer Sent">Offer Sent</option>
                    <option value="Offer Accepted">Offer Accepted</option>
                    <option value="Offer Received">Offer Received</option>
                    <option value="Offer Declined">Offer Declined</option>
                  </select>
                </div>
              </div>
              <!-- end of droplist -->
              <form>
                <input
                  type="text"
                  value=""
                  placeholder="Search from transactions"
                />
                <button type="submit"></button>
              </form>
            </div>
            <!-- end of filter -->
            <!-- <div class="notrx">
              <h2>You Have No Transactions</h2>
              <router-link to="/find-property" class="button">
                Explore
              </router-link>
            </div> -->
            <!-- end of no transaction -->
            <div class="list">
              <div class="item">
                <div class="thead clearfix">
                  <div class="status">
                    <span style="background: #F4BE39;">
                      Waiting Approval
                    </span>
                  </div>
                  <!-- end of status -->
                  <div class="meta">
                    <span>1384653973</span>
                    <span>18 Nov 2021</span>
                  </div>
                  <!-- end of meta -->
                </div>
                <!-- end of thead -->
                <div class="detail">
                  <div class="prop">
                    <figure>
                      <router-link :to="`/account/transaction/1`">
                        <img src="/img/dummy/acc/1.jpg" width="76" />
                      </router-link>
                    </figure>
                    <div class="caption">
                      <h3>
                        <router-link :to="`/account/transaction/1`">
                          Cluster Eureka at Nevada
                        </router-link>
                      </h3>
                      <div class="location cvr-bg-bf">
                        <span>Padalarang, Bandung</span>
                        <span>Furnished</span>
                      </div>
                    </div>
                  </div>
                  <!-- end of prop -->
                  <div class="type">
                    <small>Transaction Type</small>
                    <strong>Rent-to-Own</strong>
                  </div>
                  <!-- end of type -->
                  <div class="payment">
                    <!-- <small>Price per month</small>
                    <strong>Rp 3.480.000</strong>
                    <small>Price</small>
                    <strong>Rp 707.000.000</strong> -->
                    <small>Price per month & Agreed price</small>
                    <strong>Rp 3.480.000 <span>Rp 707.000.000</span></strong>
                    <!-- <small>Next payment</small>
                    <strong>18 Nov 2021 - Rp 3.654.000</strong> -->
                  </div>
                  <!-- end of payment -->
                </div>
                <!-- end of detail -->
              </div>
              <!-- end of item -->
            </div>
          </div>
          <!-- end of inner accsetting -->
        </article>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of inner mort -->
  </div>
</template>

<script>
import SidebarAccount from "./SidebarAccount.vue";
// import * as moment from "moment";
// import cookie from "js-cookie";
// import $ from "jquery";

export default {
  name: "AccountTransaction",
  metaInfo: {
    title: "Account Transaction"
  },
  components: {
    SidebarAccount
  },
  data() {
    return {
      transactions: []
    };
  },
  mounted() {},
  methods: {}
};
</script>
